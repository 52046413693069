import './style/base.scss'
import './style/base.css'
import './style/base.scss'
import './style/anim.css'
import './style/custom.css'
import './style/wkt-constants.scss'
import './style/wkt-base.scss'

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/element.js'
import '@/icons' // svg icons
import ElementUI from 'element-ui'
import VueAwesomeSwiper from 'vue-awesome-swiper'
Vue.use(VueAwesomeSwiper)

Vue.config.productionTip = false
Vue.use(ElementUI)
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
