import http from '@/http'

/**
 * 选择进入惟客宝的企业
 * @param employeeChooseAppId 企业appid
 * @param employeeId 员工id
 */

const chooseEp = params => http({ url: '/org/login/choose', params })

export default {
  chooseEp,

}
