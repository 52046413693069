<template>
  <div class="home-box">
    <div class="home-header">
      <div class="title">惟客云数字化客户经营系统</div>
    </div>
    <div class="home-content">
      <div class="industry-box" v-for="(item, index) in list" :key="index">
        <div class="industry-title">
          <img :src="item.icon" class="industry-icon" />
          <div class="text">{{ item.name }}</div>
        </div>
        <div class="line"></div>
        <div class="industry-list">
          <div class="container">
            <div
              class="industry-item"
              v-for="industry in item.children"
              :key="industry.icon"
              @click="handleToJump(industry)"
              :class="industry.disable ? 'disable' : ''"
            >
              <img :src="industry.icon" class="icon" />
              <div class="name">{{ industry.name }}</div>
              <img
                :src="
                  industry.disable
                    ? require('./assets/right-gray.png')
                    : require('./assets/right.png')
                "
                class="right-icon"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import './style/app.scss'
import chooseApi from '@/api/home.js'
export default {
  name: 'home',
  components: {},
  data() {
    return {
      list: [
        {
          name: '地产',
          icon: require('./assets/estate.png'),
          children: [
            {
              name: '大会员',
              icon: require('./assets/member.png'),
              url: 'https://wakecloud.com/login.html',
              disable: false,
              appId: 1050,
            },
            {
              name: '置业',
              icon: require('./assets/home-ownership.png'),
              url: 'https://wakecloud.com/login.html',
              disable: false,
              appId: 1063,
            },
            {
              name: '物业',
              icon: require('./assets/property.png'),
              url: 'https://wakecloud.com/login.html',
              disable: true,
            },
            {
              name: '商业中心',
              icon: require('./assets/shopping-mall.png'),
              url: 'https://wakecloud.com/login.html',
              disable: true,
            },
            {
              name: '零售',
              icon: require('./assets/estate-retail.png'),
              url: 'https://wakecloud.com/app.html#/startup/chose',
              disable: false,
              appId: 272,
            },
            {
              name: '地产大运营',
              icon: require('./assets/estate-operation.png'),
              url: 'https://cp.wakedata.com/',
              disable: false,
            },
            {
              name: '康养',
              icon: require('./assets/health.png'),
              url: 'https://wakecloud.com',
              disable: true,
            },
          ],
        },
        {
          name: '家装',
          icon: require('./assets/home.png'),
          children: [
            {
              name: '数字化门店',
              icon: require('./assets/store.png'),
              url: 'https://www.wakedata.com/login.html',
              disable: false,
            },
            {
              name: '经营分析',
              icon: require('./assets/operation.png'),
              url: 'https://www.wakedata.com/login.html',
              disable: true,
            },
          ],
        },
        {
          name: '汽车',
          icon: require('./assets/car.png'),
          children: [
            {
              name: '数字化门店',
              icon: require('./assets/store-gray.png'),
              url: 'https://www.wakedata.com/login.html',
              disable: true,
            },
            {
              name: '经营分析',
              icon: require('./assets/operation.png'),
              url: 'https://www.wakedata.com/login.html',
              disable: true,
            },
          ],
        },
        {
          name: '零售',
          icon: require('./assets/retail.png'),
          children: [
            {
              name: '数字化门店',
              icon: require('./assets/store.png'),
              url: 'https://www.wakedata.com/login.html',
              disable: false,
            },
            {
              name: '经营分析',
              icon: require('./assets/operation.png'),
              url: 'https://www.wakedata.com/login.html',
              disable: true,
            },
          ],
        },
      ],
    }
  },

  mounted() {},

  methods: {
    handleToJump(industry) {
      if (industry.disable) {
        return
      }
      if (industry.appId) {
        return this.chooseTestEp(industry)
      }
      window.open(industry.url)
    },

    chooseTestEp(industry) {
      const params = {
        employeeChooseAppId: industry.appId,
        employeeId: 7413,
      }

      chooseApi.chooseEp(params).then((res) => {
        if (res.data && res.data.login) {
          window.open('https://wakecloud.com/wkb.html')
        } else {
          window.open('https://wakecloud.com/app.html#/startup/chose')
        }
      })
    },
  },
}
</script>
<style lang="scss">
body {
  background: #eff2f6;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  border-radius: 100px;
  background-color: #d4deee;
}

.home-box {
  // overflow: hidden;
  .home-header {
    min-width: 1440px;
    height: 309px;
    background-image: url('./assets/bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
    .title {
      width: 1200px;
      margin: 0 auto;
      margin-top: 74px;
      font-size: 24px;
      font-family: PingFangSC, PingFangSC-Medium;
      font-weight: 600;
      color: #ffffff;
      line-height: 24px;
    }
  }
  .home-content {
    position: absolute;
    top: 164px;
    margin: 0 auto;
    width: 100%;

    .industry-box {
      position: relative;
      margin: 0 auto;
      width: 1200px;
      min-height: 148px;
      background: #ffffff;
      border-radius: 6px;
      box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.07);
      margin-bottom: 34px;

      .industry-title {
        display: inline-block;
        width: 128px;
        position: absolute;
        top: -33px;
        left: 30px;
        text-align: center;
        .industry-icon {
          display: block;
          width: 128px;
          height: 128px;
        }
        .text {
          margin-top: 4px;
          font-size: 18px;
          font-family: PingFangSC, PingFangSC-Medium;
          font-weight: 600;
          text-align: center;
          color: #27292f;
          line-height: 25px;
        }
      }

      .line {
        position: relative;
        top: 10px;
        display: inline-block;
        width: 1px;
        height: 80px;
        background: #eaedf4;
        margin-left: 176px;
        margin-right: 16px;
      }

      .industry-list {
        display: inline-block;
        margin-top: 24px;
        margin-bottom: 10px;
        .container {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          flex-wrap: wrap;
          width: 1005px;
        }

        .industry-item {
          margin-bottom: 14px;
          margin-right: 13px;
          cursor: pointer;
          box-sizing: border-box;
          width: 238px;
          height: 100px;
          line-height: 100px;
          background: #ffffff;
          color: #27292f;
          border: 1px solid #e1e5ef;
          border-radius: 3px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          .icon {
            display: inline-block;
            vertical-align: middle;
            width: 60px;
            height: 60px;
            margin-left: 16px;
          }
          .name {
            width: 80px;
            display: inline-block;
            font-size: 14px;
            font-family: PingFangSC, PingFangSC-Medium;
            font-weight: 600;
            text-align: left;
            line-height: 20px;
            margin-left: 19px;
          }
          .right-icon {
            display: inline-block;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            margin-left: 30px;
          }
        }
        .industry-item:last-child {
          margin-right: 0;
        }
        .disable {
          border: 1px solid #e1e5ef;
          color: #9aa1ba;
        }
      }
    }
  }
}
</style>
