import { getUserInfo, getUserMenu } from '@/api/auth'
import types from '../types'
import router, { resetRouter } from '@/router'
import { Error404 } from '@/router/constant'
import { dynamicRoutes } from '@/router/dynamic'
import { UtilMethods } from '@/utils'
const util = new UtilMethods()

export default {
  state: {
    userInfo: null,
    permissionRoutes: [] // router最终挂载的路由
  },
  mutations: {
    // 设置用户信息
    [types.SET_USER_INFO]: (state, data) => {
      state.userInfo = data
    },
    [types.SET_MOUNTED_ROUTES]: (state, data) => {
      state.permissionRoutes = data
    }
  },
  actions: {
    // 获取用户信息，并存入vuex（持久化）
    [types.ASYNC_GET_USER_INFO_MENU]: ({ commit }) => {
      return new Promise((resolve, reject) => {
        getUserInfo()
          .then(res1 => {
            commit(types.SET_USER_INFO, { user: res1.data.data })
            getUserMenu()
              .then(res2 => {
                const userInfoMenu = {
                  user: res1.data.data,
                  menu: res2.data.data
                }
                commit(types.SET_USER_INFO, userInfoMenu)
                resolve(userInfoMenu)
              })
              .catch(err2 => {
                reject(err2)
              })
          })
          .catch(err1 => {
            commit(types.SET_USER_INFO, null)
            reject(err1)
          })
      })
    },
    /**
     * 挂载权限的方法：通过后台传回的权限，生成权限路由并进行挂载
     */
    [types.MOUNT_PERMISSION_ROUTES]: ({ commit }, permissionList) => {
      return new Promise((resolve, reject) => {
        if (util.checkDataType(permissionList) === 'array') {
          const mountedRoutes = util.deepCopy(dynamicRoutes)
          generatePermissionRoutes(mountedRoutes, permissionList) // 生成权限路由
          console.log('mountedRoutes', mountedRoutes)
          mountedRoutes.push({
            path: '*',
            name: 'error404',
            component: Error404
          })
          resetRouter()
          router.addRoutes(mountedRoutes) // 挂载路由
          commit(types.SET_MOUNTED_ROUTES, mountedRoutes)
          // 保存重置redirect后的权限路由，供菜单使用
          resolve(mountedRoutes)
        } else {
          console.error(
            `${types.MOUNT_DYNAMIC_ROUTER} permissionList: param is not array`
          )
          reject(new Error('用户权限出错!'))
        }
      })
    }
  }
}

/**
 * 动态路由表中筛选出有权限的路由表
 * 支持递归深度筛选，筛选标识path
 * @param {Array} dynamicRoutes router路由表中动态路由部分
 * @param {Array} permissionList 后台传回的权限路由表
 */
function generatePermissionRoutes(dynamicRoutes, permissionList) {
  // debugger
  if (util.checkDataType(dynamicRoutes) === 'array') {
    for (var i = 0; i < dynamicRoutes.length; i++) {
      let isAuth = false
      let index = null
      for (let j in permissionList) {
        if (dynamicRoutes[i].path === permissionList[j].path) {
          dynamicRoutes[i].meta = permissionList[j].meta
          isAuth = true
          index = j
          break
        }
      }
      if (!isAuth) {
        dynamicRoutes.splice(i, 1)
        i--
      }
      if (isAuth && dynamicRoutes[i].children) {
        generatePermissionRoutes(
          dynamicRoutes[i].children,
          permissionList[index].children
        )
      }
    }
  }
}
