export const Error404 = () =>
  import(/* webpackChunkName: 'Error404' */ '@/views/Error404')


/**
 * 前端固定挂载的路由
 * title 菜单标题，isMenu=true时，必填
 * isMenu 该路由是否展示到菜单中（默认：不展示到菜单）
 * 特别注意：（1）二、三级菜单的path不推荐直接以/开头命名，例如: children: {path: '/two', children: {path: '/three'}}
 * （2）如果一级菜单为不确定path，例如：{path: '/one/:id'}，二、三级菜单一定不能在菜单栏中展示(设置isMenu:false即可)，因为菜单只能生成确定path的路由
 */
export const constantRoutes = [
  {
    path: '/',
    redirect: '/home/index',
    meta: {
      isMenu: true
    }
  }
]
