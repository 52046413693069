<script>
export default {
  name: 'iconSvg',
  functional: true,
  props: {
    iconName: {
      type: String,
      required: true
    },
    iconClass: String
  },
  render(h, context) {
    const { iconName, iconClass } = context.props
    const svgId = `#icon-${iconName}`
    let svgClass = 'icon-svg'
    if (iconClass) svgClass = iconClass
    return (
      <svg class={svgClass} aria-hidden="true">
        <use xlinkHref={svgId}></use>
      </svg>
    )
  }
}
</script>

<style>
.icon-svg {
  width: 1em;
  height: 1em;
  fill: currentColor;
  overflow: hidden;
}
</style>
