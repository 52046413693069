// md统一认证接口
import http from '@/http'

/**
 * 验权
 */
export const queryRoot = () => http({ url: '/dm/endpoint/category/query_root' })

/**
 * 获取登录用户信息，未登录时返回登陆页面地址
 */
export const getUserInfo = () => http({ url: '/manager/me' })

/**
 * 获取登录用户信息，未登录时返回登陆页面地址
 */
export const getUserMenu = () => http({ url: '/manager/menu' })

/**
 * 退出系统
 */
export const logout = () =>
  http({
    url: '/manager/logout',
    method: 'post'
  })

/**
 * 获取登录或登出成功后的重定向地址
 */
export const getLoginUrl = () => http({ url: '/manager/sign_in' })
