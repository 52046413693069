import axios from 'axios'
// import { Message } from 'element-ui'
// import { REDIRECT } from './error'

// 创建实例
const http = axios.create({
  timeout: 60000,
  headers: {
    'Content-Type': 'application/json;charset=UTF-8'
  },
  withCredentials: true,
  validateStatus: function (status) {
    return status < 500
  }
})
http.defaults.withCredentials = true // 跨域携带凭证cookie
// http.defaults.baseURL = process.env.VUE_APP_HTTP_BASE_URL

// 请求拦截
http.interceptors.request.use(
  config => {
    const req = { ...config }
    if (!req.method) req.method = 'get' // 默认get请求
    return req
  },
  error => Promise.reject(error)
)

// 响应拦截
http.interceptors.response.use(
  res => {
    if (res.status === 200) {

      // 请求成功
      return Promise.resolve(res.data)

    }
    // 所有不需要特殊处理的错误，均提示报错并返回错误信息
    // Message.error(res.data.msg || '未知')
    return Promise.reject(res)
  },
  err => {
    if (err && err.msg && err.msg.includes('timeout')) {
      // Message.error('请求超时')
    } else {
      // Message.error('服务器异常')
    }
    return Promise.reject(err)
  }
)

export default http
