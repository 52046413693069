import Vue from 'vue'
import VueRouter from 'vue-router'
// import { queryRoot } from '@/api/auth'
// import store from '../store'
// import types from '../store/types'
// import { Storage, UtilMethods } from '../utils'
import { constantRoutes } from './constant'
// const util = new UtilMethods()
// const storage = new Storage()

Vue.use(VueRouter)

const router = new VueRouter({
  routes: constantRoutes
})

// queryRoot()

// // 全局守卫
// router.beforeEach((to, from, next) => {
//   document.title = to.meta.title ? to.meta.title : ''
//   const vuex = storage.get('vuex')
//   const { permissionRoutes } = store.state.permission
//   if (vuex && !util.checkIsEmpty(vuex.userInfo)) {
//     // 是否登录即缓存中是否有登录记录
//     if (!util.checkIsEmpty(permissionRoutes)) {
//       // 已挂载路由或者游客
//       next()
//       return
//     }
//   }
//   store.dispatch(types.ASYNC_GET_USER_INFO_MENU).then(res => {
//     console.log(res)
//     // 未登录或者未挂载路由
//     store.dispatch(types.MOUNT_PERMISSION_ROUTES, res.menu).then(() => {
//       // if (res.user.roles.join().indexOf('anonymous') === -1) {
//       //   // 进入挂载路由第一个path
//       //   let path = '/'
//       //   next(path)
//       //   return
//       // }
//       next({ ...to, replace: true })
//     })
//   })
// })

export default router

/**
 * 挂载路由前重置路由
 * 为解决addRoutes挂载路由时，上一次挂载的路由不会重置问题
 */
export function resetRouter() {
  router.matcher = new VueRouter({
    routes: constantRoutes
  }).matcher
}
