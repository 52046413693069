// 定义mutations、actions的方法名
export default {
  // modules permission
  // 获取用户信息 -- actions
  ASYNC_GET_USER_INFO_MENU: 'ASYNC_GET_USER_INFO_MENU',
  // 获取所有标签 -- actions
  ASYNC_GET_ALL_TAGS: 'ASYNC_GET_ALL_TAGS',
  // 挂载生成的权限路由
  MOUNT_PERMISSION_ROUTES: 'MOUNT_PERMISSION_ROUTES',
  // 保存用户信息 -- mutations
  SET_USER_INFO: 'SET_USER_INFO',
  // 挂载的路由
  SET_MOUNTED_ROUTES: 'SET_MOUNTED_ROUTES',
  // 保存所有标签 -- mutations
  SET_ALL_TAGS: 'SET_ALL_TAGS'
}
