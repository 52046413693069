/**
 * 前端动态挂载的路由
 * 根据后台给的权限来筛选出需要挂载的路由
 */
export const dynamicRoutes = [
  {
    path: '/module1',
    meta: {
      title: '模块一',
      isMenu: true
    },
    children: [
      {
        path: 'page1',
        meta: {
          title: '页面1',
          isMenu: true
        },
        children: [
          {
            path: 'page1_1',
            meta: {
              title: '页面1_1',
              isMenu: true
            }
          },
          {
            path: 'page1_2',
            meta: {
              title: '页面1_2',
              isMenu: true
            }
          }
        ]
      },
      {
        path: 'page2',
        meta: {
          title: '页面2',
          isMenu: true
        },
        children: []
      }
    ]
  },
  {
    path: '/module2',
    meta: {
      title: '模块二',
      isMenu: true
    },
    children: [
      {
        path: 'page3',
        meta: {
          title: '页面3',
          isMenu: true
        },
        children: [
          {
            path: 'page3_1',
            meta: {
              title: '页面3_1',
              isMenu: true
            }
          }
        ]
      }
    ]
  }
]
